<script setup lang="ts">
import { Vue3SlideUpDown } from 'vue3-slide-up-down';

const modelValue = defineModel<boolean>({ default: false });
</script>

<template>
  <Vue3SlideUpDown
    v-model="modelValue"
    :duration="300"
  >
    <slot />
  </Vue3SlideUpDown>
</template>
